<template>
  <div>
    <v-card class="mx-2 mb-1 elevation-5 rounded-lg">
      <ProfilDaerahMainMap></ProfilDaerahMainMap>
    </v-card>
    <ProfilDaerahMainTable
        v-if="true"
        :jenis="jenis"
    ></ProfilDaerahMainTable>

    <InfoDialog
        :infoDialogMessage="infoDialogMessage"
        ref="infoDialog"
    ></InfoDialog>


 </div>
</template>

<script>

import ProfilDaerahMainMap from "@/components/public_daerah/ProfilDaerahMainMap";
import ProfilDaerahMainTable from "@/components/public_daerah/ProfilDaerahMainTable";
import InfoDialog from "@/components/utils/InfoDialog";

export default {
  name: 'ProfilDaerahMain',
  props:{
    jenis: String
  },
  components: {
    InfoDialog,
    ProfilDaerahMainTable,

    ProfilDaerahMainMap
  },
  data(){
    return {
      showInfo : true,
      infoDialogMessage: ''
    }
  },
  methods:{
    dialogInfoShow(){
      this.infoDialogMessage = 'hello bos oke deh'
      this.$refs.infoDialog.showDialog()
    },


  },
  mounted() {
    /**
     * NANTI DIPINDAH DENGA KONSEP PEMBATASAN BIAR LOADING TIDAK LAMA
     */


  },


}
</script>

<style scoped>

</style>