const EPotensiType = Object.freeze({
    IPRO: 'IPRO',
    PELUANG: 'PELUANG',
    POTENSI:'POTENSI',

    KI: 'KI',
    KIH: 'KIH',
    KPI: 'KPI',
    KEK: 'KEK',
})

const EPotensiTypes= Object.freeze([
    {
        id: EPotensiType.IPRO,
        description: 'IPRO',
        descriptionEn: 'IPRO'
    },
    {
        id: EPotensiType.PELUANG,
        description: 'PELUANG',
        descriptionEn: 'OPPORTUNITY',
    },
    {
        id: EPotensiType.POTENSI,
        description: 'POTENSI',
        descriptionEn: 'POTENTIAL'
    },

    {
        id: EPotensiType.KI,
        description: 'KI',
        descriptionEn: 'KI',
    },
    {
        id: EPotensiType.KIH,
        description: 'KIH',
        descriptionEn: 'KIH',
    },
    {
        id: EPotensiType.KPI,
        description: 'KPI',
        descriptionEn: 'KPI',
    },
    {
        id: EPotensiType.KEK,
        description: 'KEK',
        descriptionEn: 'KEK',
    },


])

export  {EPotensiType as default , EPotensiTypes}