<template>
  <div class="fill-height">
    <ProfilDaerahMain
        jenis="DERAH"
    ></ProfilDaerahMain>
  </div>
</template>

<script>
import ProfilDaerahMain from "../../components/public_daerah/ProfilDaerahMain";

export default {
  name: "ProfilDaerahView",
  components:{
    ProfilDaerahMain
  }
}
</script>

<style scoped>

</style>