<template>
    <l-map
        :center="computedCurrentMarker"
        :zoom="zoom"
        :max-zoom="maxZoom"
        ref="map"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        style="z-index: 0; height: 500px; "
        :options="{scrollWheelZoom: false}"
    >
      <l-tile-layer
          :url="url"
          attribution="OpenStreetMap"
      ></l-tile-layer>

      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"/>

      <l-marker
          v-for="marker in computedMarkers "
          :key="marker.id"
          :lat-lng="marker.coordinates"
      >
        <l-tooltip>{{ lookupFArea(marker.id).description }}</l-tooltip>
        <l-icon
            :icon-size="marker.iconSize"
            :icon-anchor="marker.iconAnchor"
            :icon-url="marker.iconUrl"
        />

        <l-popup onchange="innerClick">
          <v-flex class="d-flex caption font-weight-bold">
            <v-icon x-small class="mr-1">mdi-tag</v-icon> {{ lookupFSectorType(lookupFPotensi(marker.id).fsectorTypeBean).description }}
            {{ lookupFArea(marker.id).description }}
          </v-flex>
<!--          <v-flex class="d-flex">-->
<!--            {{ lookupFPotensi(marker.id).kode2 }}-->
<!--          </v-flex>-->
<!--          <v-flex class="mt-1" v-if="lookupFPotensi(marker.id).district !==undefined">-->
<!--            <div class="caption font-weight-bold ">-->
<!--              {{ lookupFArea(marker.id).description }}-->
<!--              {{ lookupFPotensi(marker.id).district }}-->
<!--              ,-->
<!--              {{ lookupFPotensi(marker.id).city }}-->
<!--            </div>-->
<!--          </v-flex>-->


          <v-flex class="mt-2 d-flex">

<!--            <router-link style="text-decoration: none" :to="`public-potensi-detail/` + linkNeated(marker.id.toString())">-->
<!--              <v-btn color="blue lighten-2" dark x-small >-->
<!--                <div class="caption align-baseline">-->
<!--                  <v-icon x-small>mdi-tag</v-icon>-->
<!--                  Lihat Detil-->
<!--                </div>-->
<!--              </v-btn>-->
<!--            </router-link>-->

            <router-link style="text-decoration: none" :to="`public-profil-daerah-detil/` + linkNeated(marker.id.toString())">
              <v-btn color="blue lighten-2" dark x-small >
                <div class="caption align-baseline">
                  <v-icon x-small>mdi-tag</v-icon>
                  {{$t('lihatDetail')}}
                </div>
              </v-btn>
            </router-link>


            <v-spacer></v-spacer>
            <v-btn @click="openGoogleMap(lookupFPotensi(marker.id))" x-small plain>
              <v-icon small color="orange">mdi-map-marker</v-icon>
              Google
            </v-btn>

          </v-flex>

        </l-popup>

      </l-marker>

      <l-control-polyline-measure :options="{ showUnitControl: true }" position="topleft"/>
    </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon, LPopup, LControlLayers, LTooltip } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure';

import FileService from "../../services/apiservices/file-service";
import {format, parseISO} from "date-fns";
import FPotensiService from "@/services/apiservices/f-potensi-service";

export default {
  name: "IproMainMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LControlLayers,
    LTooltip,
    LControlPolylineMeasure
  },
  data () {
    return {

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 9999,
      pageSizes: [9, 25, 50, 150, 500, 9999],

      search: '',

      showGallery: false,
      showCctv: false,

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // center: [ -2.692921, 111.634003 ],
      zoom: 8,
      maxZoom: 18,

      currentMarker:{id: 1, coordinates: [-7.4220966,112.8667491]},
      // markers: [
      // {id: 1, coordinates: [-2.692921,111.634003]},
      // {id: 2, coordinates: [-2.692920,111.645003]},
      // ],
      fPotensies:[],

      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'OpenTopoMap',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
              'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: 'Satelite',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }
      ],



    }
  },
  computed:{
    computedCurrentMarker(){
      return this.currentMarker.coordinates
    },
    computedMarkersFpotensies(){
      let markers = []

      this.fPotensies.forEach( item =>{
        if (item.lat !==0 && item.lon !==0){
          let marker = ''

          const sectorType = this.lookupFSectorType(item.fsectorTypeBean)
          const sectorTypeMarker1 = sectorType.markerImage1

          // iconUrl: './assets/images/jagung.png',

          marker = {id: item.id, coordinates: [item.lat, item.lon],
            iconUrl: this.lookupImageMarkerUrl(sectorTypeMarker1),
            iconSize: [45, 65],
          },
          markers.push(marker)
        }
      })
      return markers
    },

    computedMarkers(){
      let markers = []

      this.$store.state.potensi.itemsFArea.forEach( item =>{
        if (item.lat !==0 && item.lon !==0){
          let marker = ''

          // const sectorType = this.lookupFSectorType(item.fsectorTypeBean)
          const markerImage1 = item.markerImage1

          // iconUrl: './assets/images/jagung.png',

          marker = {id: item.id, coordinates: [item.lat, item.lon],
            iconUrl: this.lookupImageMarkerUrl(markerImage1),
            iconSize: [45, 45],
          }
          markers.push(marker)
        }
      })
      return markers
    },

    computedPotensies(){
      return this.fPotensies
    },

    computedItemsFSectorType(){
      return this.$store.state.potensi.itemsFSectorType
    },
    computedItemsFKomoditiType(){
      return this.$store.state.potensi.itemsFKomoditiType
    }


  },
  methods: {
    streetmap(){
      this.maxZoom =18
      this.url= 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      // let mapboxAccessToken = 'pk.eyJ1IjoiZGVzamF0aW0xIiwiYSI6ImNreWN4ZndibTB0cTQyb3FrdHp5cWJsZ2sifQ.XXVZsp7srickUzaDMzjMPw'
      // this.url= `mapbox://styles/mapbox/streets-v11?access_token=${mapboxAccessToken}`
    },
    satelite(){
      this.maxZoom =17
      this.url = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
    },
    zoomUpdated (zoom) {
      this.zoom = zoom;
      // console.log(this.markers)
    },
    centerUpdated (center) {
      this.center = center;
    },
    markerClicked(value){
      // console.log(value)
      this.retrieveFiles(value)

    },
    retrieveFiles(id){
      console.log(id)
    },
    innerClick() {
      alert("Click!");
    },
    fetchParent(){
    },

    lookupFPotensi (fpotensiBean) {
      const items = this.computedPotensies.filter(x => x.id==fpotensiBean)
      if (items.length>0){
        return items[0]
      }else {
        return Object
      }
    },

    lookupFSectorType (fsectorTypeBean) {
      const str = this.computedItemsFSectorType.filter(x => x.id===fsectorTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFSubArea (fsubAreaBean) {
      const items = this.$store.state.potensi.itemsFSubArea.filter(x => x.id==fsubAreaBean)
      if (items.length>0){
        return items[0]
      }else {
        return Object
      }
    },
    lookupFArea (fareaBean) {
      const items = this.$store.state.potensi.itemsFArea.filter(x => x.id==fareaBean)
      if (items.length>0){
        return items[0]
      }else {
        return Object
      }
    },
    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },
    lookupImageMarkerUrl(markerImage){
      if (markerImage===undefined || markerImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_ori(markerImage)
      }
    },

    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },
    openGoogleMap(item) {

      const urlSuffix = `${item.lat},${item.lon}&z=15`
      window.open(
          "https://maps.google.com/maps?q=" + urlSuffix,
          "_blank"
      );
    },
  },

  mounted() {

    const lat = this.$route.params.lat
    const lon = this.$route.params.lon
    const id = this.$route.params.id
    if (lat !==undefined && lon !==undefined){
      this.currentMarker = {id: id, coordinates: [lat,lon] }
    }else {
      this.currentMarker =  {id: 0, coordinates: [-7.4220966,112.8667491]} //tidak ditampilkan
    }
    // console.log(this.currentMarker.id)

    FPotensiService.getAllFPotensiContainingPublic(this.currentPage, this.pageSize, "created", "DESC", this.search, this.jenis).then(
        response => {
          // console.log(response.data.items.length)

          const { items, totalPages} = response.data
          this.fPotensies = items
          this.$store.dispatch('potensi/updateItemsFPotensi', items)

          this.totalPaginationPages = totalPages
        },
        error => {
          console.log(error)
        }
    )


  }
}
</script>

<style scoped>

</style>