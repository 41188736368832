<template>
  <v-card class="elevation-0 mt-4 service bg-light section text-xs-center">

    <v-card-text>
      <v-row no-gutters class="ml-10 mr-10" v-if="false">
        <v-col cols="12" sm="3" md="3">
          <v-text-field
              v-on:keyup.enter="searchOnEnter"
              v-on:blur="searchOnEnter"
              append-icon="mdi-magnify"
              outlined
              dense
              :hint="$t('hint')"
              :label="$t('cari')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-autocomplete
              :items="itemsKabKota"
              outlined
              dense
              chips
              small-chips
              :label="$t('kabupatenKota')"
              multiple
              class="ml-1 mr-1"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-autocomplete
              v-model="filterFsectorTypeBean"
              :items="computedItemsFSectorType"
              item-value="id"
              item-text="description"
              outlined
              dense
              chips
              small-chips
              :label="$t('sektor')"
              multiple
              class="ml-1 mr-1"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-autocomplete
              v-model="filterFkomoditiTypeBean"
              :items="computedItemsFKomoditiType"
              item-value="id"
              item-text="description"
              outlined
              dense
              chips
              small-chips
              :label="$t('komoditi')"
              class="ml-1 mr-1"
              multiple
              color="red"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-btn
          class="pl-8 pr-8 "
          color="primary"
          >
            <v-icon small>mdi-magnify</v-icon>
            {{$t('cari')}}
          </v-btn>
        </v-col>

      </v-row>
      <v-row class="ml-4">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
              v-model="filterKabupaten"
              append-icon="mdi-magnify"
              outlined
              dense
              :label="$t('filterNamaKabupaten')"
              hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="py-4" v-if="fAreaFiltered.length < 1" align="center">
        <v-col cols="12" align="center">
          <span>{{lang==='id'? 'Data Tidak Tersedia' : 'No data available'}}</span>
        </v-col>
      </v-row>
      <v-layout wrap justify-center>
        <div
          v-for="(item, i) in fAreaFiltered"
          :key="i"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                  width="150"
                  height="200"
                  xs6 sm4
                  class="mx-2 my-2 rounded-lg"
                  :elevation="hover? 12:4 "
              >
                <v-card-title class="text-center">
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <router-link style="text-decoration: none" :to="`public-profil-daerah-detil/` + linkNeated(item.id.toString())">
                        <v-img
                            height="120"
                            width="120"
                            :lazy-src="lookupImageUrlLazy(item)"
                            :src="lookupImageUrl(item)"
                            :class="{ 'hover-image': hover }"
                        ></v-img>
                      </router-link>
                    </template>
                  </v-hover>
                </v-card-title>

                <v-card-text class="text-center mt-n4 small-line-height">
                  <div class="">
                      {{item.description}}
                  </div>
                  <div class="caption mt-1 orange--text" v-if="hitungPeluang(item.description) >0">
                    <v-icon x-small color="orange" class="text--lighten-2">mdi-tag</v-icon>
                    {{ hitungPeluang(item.description) }} {{$t('peluang')}}
                  </div>

                </v-card-text>
              </v-card>
            </template>
          </v-hover>

        </div>

      </v-layout>

      <v-container>
        <v-row justify="center" align="center">
          <v-col
              cols="10"
              md="9"
              sm="8"
              align="right"
          >
            <v-pagination
                v-model="currentPage"
                :length="totalPaginationPages"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-container>

    </v-card-text>

    <PublicFooter></PublicFooter>


  </v-card>
</template>

<script>

import FAreaService from '@/services/apiservices/f-area-service';
import FileService from "@/services/apiservices/file-service"

import FDivision from '@/models/f-division'

import {format, parseISO} from "date-fns";
import PublicFooter from "@/components/PublicFooter";
import FSectorTypeService from "@/services/apiservices/f-sector-type-service";
import FKomoditiTypeService from "@/services/apiservices/f-komoditi-type-service";
import EPotensiType from "@/models/e-potensi-type";

export default {
  name: 'IproMainTable',
  props:{
    jenis: String
  },
  components: {
    PublicFooter
  },
  data () {
    return {
      filterKabupaten: '',
      snackbar: false,
      snackBarMesage: '',

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 15,
      pageSizes: [9, 25, 50, 150, 500],

      search: '',
      filterFsectorTypeBean: '',
      filterFkomoditiTypeBean: '',
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'coverImage',
          width: '20%'
        },
        { text: '', value: 'title', width:'30%' },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fAreaes:[],
      itemsFDivision: [
        new FDivision()
      ],
      itemsKabKota:[
        'Kota Surabaya', 'Kab Sidoarjo', 'Kab Mojokerto', 'Kota Malang', 'Kab Malang', 'Kota Kediri', 'Kab Kediri', 'Kab Lamongan'
      ],
      // itemsSektor:[
      //     'Industri', 'Infrastruktur', 'Pariwisata', 'Kawasan Ekonomi', 'Perikanan', 'Pertanian', 'Peternakan', 'Perkebunan', 'Pertambangan', 'Industri Terintegrasi'
      // ],
      // itemsKomoditi:[
      //   'Tembaga', 'Kayu & Olahannya', 'Produk Perhiasan atau Permata', 'Industri Lemak dan Nabati',
      //   'Padi', 'Jagung', 'Cabai', 'Kacang-kacangan', 'Ubi',
      //     'Kakap', 'Tuna', 'Udang', 'Rajungan', 'Cumi-cumi', 'Gurita', 'Rumput Laut Kering',
      //     'Mangga', 'Apel', 'Sawit', 'Karet', 'Kelapa', 'Pisang'
      // ],

      // itemsFSectorType:[],
      // itemsFKomoditiType: [],


    }
  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFArea()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFArea()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    fAreaFiltered(){

      let areaModified =[]
      const listArea = this.$store.state.potensi.itemsFArea
      for (let i=0; i< listArea.length; i++){
        let itemBerita = listArea[i];
        if (itemBerita.contentBody !==undefined){
          if (itemBerita.contentBody.length>100){
            itemBerita.contentBody = itemBerita.contentBody.substr(0, 99)
          }

        }
        // console.log(itemBerita)
        areaModified.push(itemBerita)
      }
      return areaModified.filter( x => x.description.toUpperCase().includes(this.filterKabupaten.toUpperCase()));
    },



    computedItemsFSectorType(){
      return this.$store.state.potensi.itemsFSectorType
    },
    computedItemsFKomoditiType(){
      return this.$store.state.potensi.itemsFKomoditiType
    }

  },
  methods: {
    hitungPeluang(city){

      let countPeluang = 0
      this.$store.state.potensi.itemsFPotensi.forEach( item => {
        if (city.toUpperCase().trim().includes(item.city.toUpperCase().trim())){
          if (item.jenis.includes(EPotensiType.PELUANG) || item.jenis.includes(EPotensiType.IPRO) ){
            countPeluang++;
          }
        }
      })

      return countPeluang
    },

    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFArea()
      }
    },
    fetchParent(){

      FSectorTypeService.getAllFSectorTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFSectorType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )
      FKomoditiTypeService.getAllFKomoditiTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFKomoditiType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )
      FAreaService.getAllFAreaPublic().then(
          response => {
            // console.log(JSON.stringify(response.data))
            this.$store.dispatch('potensi/updateItemsFArea', response.data)
          },
          error => {
            console.log(error.response)
          }
      )

    },

    fetchFArea() {
      this.$store.dispatch('potensi/updateItemsFArea', [])

    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },


    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id==fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    lookupFSectorType (fsectorTypeBean) {
      const str = this.itemsFSectorType.filter(x => x.id===fsectorTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFKomoditiType (fkomoditiTypeBean) {
      const str = this.itemsFKomoditiType.filter(x => x.id===fkomoditiTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    dateTimeFormat (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy hh:mm:ss') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  },
  mounted() {
    this.fetchParent()
    this.fetchFArea()

  }

}
</script>

<style scoped>
.hover-image{
  opacity: 0.6;
  cursor:pointer;
}
.small-line-height{
  line-height: 1em;
}

</style>